<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="min-height: 400px">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Subject</th>
<!--                                        <th>Status</th>-->
                                        <th>Created</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="contacts.length > 0">
                                        <tr v-for="(contact, idx) in contacts" :key="idx">
                                            <td>
                                                <strong>{{ idx+1 }}</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span class="w-space-no">{{ contact.data.name }}</span>
                                                </div>
                                            </td>
                                            <td>{{contact.data.email}}	</td>
                                            <td>{{contact.data.subject}}</td>
<!--                                            <td>-->
<!--                                                <div class="d-flex align-items-center">-->
<!--                                                    <template v-if="contact.data.status==='APPROVED'">-->
<!--                                                        <i class="fa fa-circle text-success mr-1"></i>-->
<!--                                                        {{ contact.data.status }}-->
<!--                                                    </template>-->
<!--                                                    <template v-else-if="contact.data.status==='PENDING'">-->
<!--                                                        <i class="fa fa-circle text-warning mr-1"></i>-->
<!--                                                        {{ contact.data.status }}-->
<!--                                                    </template>-->
<!--                                                    <template v-else>-->
<!--                                                        <i class="fa fa-circle text-danger mr-1"></i>-->
<!--                                                        {{ contact.data.status }}-->
<!--                                                    </template>-->
<!--                                                </div>-->
<!--                                            </td>-->
                                            <td>{{getReadableDate(contact.data.createdAt)}}</td>
                                            <td>
                                                <div class="d-flex">
                                                    <a @click="active_contact=contact" href="javascript:void(0)" class="btn btn-info shadow btn-xs sharp mr-1" data-toggle="modal" data-target="#viewContact">
                                                        <i class="fa fa-eye"></i>
                                                    </a>
<!--                                                    <template v-if="withdraw.data.status==='PENDING'">-->
<!--                                                        <a @click="toggleStatus(withdraw)" href="javascript:void(0)" class="btn btn-success shadow btn-xs sharp" data-toggle="tooltip" title="confirm">-->
<!--                                                            <i class="fa fa-check"></i>-->
<!--                                                        </a>-->
<!--                                                        <a @click="toggleStatus(withdraw, false)" href="javascript:void(0)" class="btn btn-danger shadow btn-xs sharp ml-1" data-toggle="tooltip" title="decline">-->
<!--                                                            <i class="fa fa-ban"></i>-->
<!--                                                        </a>-->
<!--                                                    </template>-->
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="7">
                                                <div class="col-12 text-center mt-4">
                                                    <h6 class="text-info">Empty contact history</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <view-contact :contact="active_contact"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../../utils/methodsMixin";
import ViewContact from "./ViewContact";

export default {
    name: "AllContacts",
    mixins: [methodsMixin],
    data(){
        return {
            active_contact: {id: '', data: {}}
        }
    },
    computed: {
        ...mapGetters('contact', {
            contacts: 'getContacts'
        })
    },
    methods: {

    },
    components: {
      ViewContact
    },
    mounted() {
        this.$store.dispatch('contact/fetchContacts')
    }
}
</script>

<style scoped>

</style>