<template>
    <div class="modal fade" id="viewContact">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">#TE-{{contact.id.substr(0,10)}}</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card contact-bx item-content">
                        <div class="card-body user-profile">
                            <div class="container-fluid px-0 pt-0">
                                <div class="row text-left">
                                    <div class="col-12">
                                        <h6>Name</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{contact.data.name}}</div>

                                    <div class="col-12">
                                        <h6>Email</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{contact.data.email}}</div>

                                    <div class="col-12">
                                        <h6>Subject</h6>
                                    </div>
                                    <div class="col-12 mb-3">{{contact.data.subject}}</div>

                                    <div class="col-12">
                                        <h6>Message</h6>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <p>
                                            {{contact.data.message}}
                                        </p>
                                    </div>

                                    <div class="col-12">
                                        <h6>Created at</h6>
                                    </div>
                                    <div class="col-12">{{getReadableDate(contact.data.createdAt)}}</div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger light" data-dismiss="modal">Close</button>
<!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "ViewContact",
    data(){
      return {
          user_info: {id: '', data: {}}
      }
    },
    props: ['contact'],
    mixins: [methodsMixin],
    // watch: {
    //     withdrawal(){
    //         return this.getUserInfo()
    //     }
    // },
    methods: {
        // async getUserInfo(){
        //     this.user_info = {id: '', data: {}};
        //     const response = await this.$store.dispatch('user/getUser', this.withdrawal.data.user);
        //     if(response.status){
        //         this.user_info = response.data;
        //     }
        // }
    }
}
</script>

<style scoped>

</style>